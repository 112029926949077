// Color Variables
$color-primary: #075DA8;
$color-secondary: #858796;
$color-black: #000;
$color-white: #fff;
$color-gray-1: #dddfeb;
$color-success: #0e8d5e;
$color-info: #36b9cc;
$color-warning: #E18700;
$color-danger: #e74a3b;
$color-light: #3A3B46;
$color-dark: #5a5c69;
$color-purple:#8c8cf4;
$color-form-border:#d1d3e2;

$color-blue-tint:#f8f9fc;
$color-gray-2: #EAECF4;
$light-sky-blue: #EAF5FB;
$light-sky-blue-2: #d2effd;
$color-light-gray: #c8c8c9;
$color-light-blue: #64BAFF;

$alert-danger-bg: #fadbd8;

// Hover Colors
$color-primary-hover: #0069bd;
$color-secondary-hover: #717384;
$color-success-hover: #0b7a52;
$color-info-hover: #2a96a5;
$color-warning-hover: #d17e00;
$color-danger-hover: #e02d1b;
$color-light-hover: #dde2f1;
$color-dark-hover: #484a54;

// General Variables
$font-size-base: 0.8125rem;
$line-height-base: 1.5;
$btn-border-radius: 0.35rem;
$btn-padding-y: 0.375rem;
$btn-padding-x: 0.75rem;
$transition-base: 0.15s ease-in-out;
$btn-text-color-default: $color-secondary;
$btn-bg-color-default: transparent;
$btn-border-color-default: transparent;


//status Variables
$color-priority-high-bg: #FFCCDD; // Duplicate of $color-status-in-development-bg
$color-priority-medium-bg: #FFE4CC; // Duplicate of $color-status-in-design-bg
$color-priority-medium-text: #80644D; // Duplicate of $color-status-in-design-text
$color-priority-low-bg: #E0E0E0; // Duplicate of $color-status-on-hold-bg
$color-priority-low-text: #858585; // Duplicate of $color-status-on-hold-text
$color-overdue-bg: #fcf5f5;
$color-status-to-do-bg: #C5F5F9;
$color-status-to-do-text: #042B4D;
$color-status-in-progress-bg: #F9F0BC; // Duplicate of $color-tag-bg
$color-status-in-progress-text: #4D4A3A; // Duplicate of $color-tag-text
$color-status-completed-bg: #C1E1C1; // Duplicate of $color-duedate-today-bg
$color-status-completed-text: #3D4D3C; // Duplicate of $color-duedate-today-text
$color-status-backlog-bg: #B1CDE6;
$color-status-backlog-text: #627280;
$color-status-in-design-bg: $color-priority-medium-bg;
$color-status-in-design-text: $color-priority-medium-text;
$color-status-design-review-bg: #CCDFFF; // Duplicate of $color-assignedto-bg
$color-status-design-review-text: #4D5F80; // Duplicate of $color-assignedto-text
$color-status-in-development-bg: $color-priority-high-bg;
$color-status-in-development-text: #805966;
$color-status-development-completed-bg: #EEDCF5; // Duplicate of $color-duedate-past-due-date-overdue-bg
$color-status-development-completed-text: #755980; // Duplicate of $color-duedate-past-due-date-overdue-text
$color-status-in-testing-bg: #E8DDCC; // Duplicate of $color-duedate-future-bg
$color-status-in-testing-text: #807059; // Duplicate of $color-duedate-future-text
$color-status-ready-for-live-bg: #dae8bc;
$color-status-ready-for-live-text: #4c553a;
$color-status-on-hold-bg: $color-priority-low-bg;
$color-status-on-hold-text: $color-priority-low-text;
$color-duedate-today-bg: $color-status-completed-bg;
$color-duedate-today-text: $color-status-completed-text;
$color-duedate-past-due-date-overdue-bg: $color-status-development-completed-bg;
$color-duedate-past-due-date-overdue-text: $color-status-development-completed-text;
$color-duedate-future-bg: $color-status-in-testing-bg;
$color-duedate-future-text: $color-status-in-testing-text;
$color-assignedto-bg: $color-status-design-review-bg;
$color-assignedto-text: $color-status-design-review-text;
$color-tag-bg: $color-status-in-progress-bg;
$color-tag-text: $color-status-in-progress-text;
$color-no-priority-bg: #b7afaf;
$color-unassigned-text: $color-white;


//Mixin for text overflow hidden ellipsse

@mixin text-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin split-activity{
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-left-color: $color-status-completed-bg;
    border-width: 10px;
    margin-top: -10px;
}

// Mixin for Button Styles
@mixin button-style($color, $background, $border, $hover-bg, $hover-border, $hover-color) {
  color: $color;
  background-color: $background;
  border: 1px solid $border;
  &:hover {
    color: $hover-color;
    background-color: $hover-bg;
    border-color: $hover-border;
  }
  &:focus, &.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba($border, 0.5);
  }
  &:disabled, &.disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}


// Button Classes
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: $btn-padding-y $btn-padding-x;
  font-size: $font-size-base;
  line-height: $line-height-base;
  border-radius: $btn-border-radius;
  transition: color $transition-base, background-color $transition-base, border-color $transition-base, box-shadow $transition-base;
}

.btn-primary { @include button-style($color-white, $color-primary, $color-primary, $color-primary-hover, $color-primary-hover, $color-white); }
.btn-secondary { @include button-style($color-white, $color-secondary, $color-secondary, $color-secondary-hover, $color-secondary-hover, $color-white); }
.btn-success { @include button-style($color-white, $color-success, $color-success, $color-success-hover, $color-success-hover, $color-white); }
.btn-info { @include button-style($color-white, $color-info, $color-info, $color-info-hover, $color-info-hover, $color-white); }
.btn-warning { @include button-style($color-white, $color-warning, $color-warning, $color-warning-hover, $color-warning-hover, $color-white); }
.btn-danger { @include button-style($color-white, $color-danger, $color-danger, $color-danger-hover, $color-danger-hover, $color-white); }
.btn-light { @include button-style($color-light, $color-gray-1, $color-light, $color-light-hover, $color-light-hover, $color-light); }
.btn-dark { @include button-style($color-white, $color-dark, $color-dark, $color-dark-hover, $color-dark-hover, $color-white); }

// Outline Buttons
.btn-outline-primary { @include button-style($color-primary, transparent, $color-primary, $color-primary-hover, $color-primary-hover, $color-white); }
.btn-outline-secondary { @include button-style($color-secondary, transparent, $color-secondary, $color-secondary-hover, $color-secondary-hover, $color-white); }
.btn-outline-success { @include button-style($color-success, transparent, $color-success, $color-success-hover, $color-success-hover, $color-white); }
.btn-outline-info { @include button-style($color-info, transparent, $color-info, $color-info-hover, $color-info-hover, $color-white); }
.btn-outline-warning { @include button-style($color-warning, transparent, $color-warning, $color-warning-hover, $color-warning-hover, $color-white); }
.btn-outline-danger { @include button-style($color-danger, transparent, $color-danger, $color-danger-hover, $color-danger-hover, $color-white); }
.btn-outline-light { @include button-style($color-light, transparent, $color-light, $color-light-hover, $color-light-hover, $color-secondary); }
.btn-outline-dark { @include button-style($color-dark, transparent, $color-dark, $color-dark-hover, $color-dark-hover, $color-white); }

// Small Button Class
.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7109375rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.015625rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}


// Additional Styles
.bg-gradient-primary { background-color: $color-primary !important; background-image: unset !important; }
.table .head-row { background-color: $color-white; color: $color-primary; }

.page-link {
  position: relative;
  display: block;
  padding: 0.2rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: $color-primary;
  background-color: $color-white;
  border: 1px solid $color-gray-1;

  &:hover {
    z-index: 2;
    color: $color-primary;
    text-decoration: none;
    background-color: $color-gray-2;
    border-color: $color-gray-1;
  }

  &:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 126, 227, 0.25);
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: $color-white;
  background-color: $color-primary;
  border-color: $color-primary;
}

.page-item.disabled .page-link {
  color: $color-secondary;
  pointer-events: none;
  cursor: auto;
  background-color: $color-white;
  border-color: $color-gray-1;
}

.border-primary {
  border-color: $color-primary !important;
}

.text-primary {
  color: $color-primary !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: $color-white;
  border-color: $color-primary;
  background-color: $color-primary;
}


.form-control:focus {
  color: $color-light;
  background-color: $color-white;
  border-color: $color-light-blue;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 126, 227, 0.25);
}

.form-control:disabled, .form-control[readonly] {
  background-color: $color-gray-2;
  opacity: 1;
}

.btn-link {
  font-weight: 400;
  color: $color-primary;
}

.bg-primary {
  background-color: $color-primary !important;
}

.disabled-section{
  opacity: .7;
  pointer-events: none;
}

// Media Query for Reduced Motion
@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
