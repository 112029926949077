#page-nav {
    white-space: nowrap;
    flex-wrap: nowrap;
    width: 100%;

    .nav-item {
        position: relative;
        padding: 4px 16px;
        z-index: 1;
        color: $color-white !important;
        margin-right: 2px;
        transition: 0.05s all;
        background: $color-primary;
        border: 1px solid $color-primary;
        border-bottom: 0 !important;
        border-radius: 10px 10px 0 0 !important;
        cursor: pointer;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        transition: all 0.2s;

        &:only-child .tab-close { 
            display: none !important;
        }
        &.active,&:hover{
            color: $color-primary !important;
            background: $color-white;
            border: 1px solid $color-primary;
            border-bottom: 0 !important;
            &::after{
                background: $color-white;
                border-bottom: 0;
                margin-bottom: -1px;
            }
        }

        .lookup-display-record {
            color: $color-white;
        }
        &.active .lookup-display-record, &:hover .lookup-display-record{
            color: $color-primary !important;
        }

        .tab-close {
            display: inline-block;
            position: absolute !important;
            right: 0;
            top: 0;
            height: 30px;
            padding: 5px;
            box-sizing: border-box;
            display: none;
        }
        &:hover {
            .tab-close{
                display: block;
            }
        }
    }
}

.object-detail {
    #page-nav {
        padding-left: 0;
        margin-left: 0;
        .nav-item {
            padding: 4px 16px;
            border-bottom: 1px solid transparent;
            margin: 0;
            &::after{
                display: none;
            }
            &.active, &:hover{
                color: $color-primary;
                border-bottom: 2px solid $color-primary;
            }

        }
    }
    .nav-tabs {
        border-bottom-color: $color-primary;
    }
}



.object-detail-tab{

    &.main-panel{
        &>nav {
            position: sticky;
            top: 81px;
            background-color: $color-white;
            z-index: 1;
            border-radius: .25rem .25rem 0 0;
        }
        .nav-tabs{
            border-bottom-color: $color-primary;
            .nav-link {
                color: $color-black;
                border: 0;
                border-bottom: 2px solid transparent;
                transition: all .3s;
            }
            
             .nav-link.active,.nav-item.show .nav-link {
                color: $color-primary;
                border: 0;
                border-bottom: 2px solid $color-primary;
                cursor: pointer;
            }

        }
    }

    .rounded-circle {
        width: 30px;
        height: 30px;
        line-height: 30px;
        box-sizing: border-box;
        padding: 0;
    }
    
}
.mt-3.object-detail-tab.main-panel {
    margin-top: 0 !important;
}

// Marketing Editor Tabs

.marketing-editor {
    .nav-tabs {
        background: $color-primary;
        width: 45px;
        display: inline-block;
        height: 100vh;
        position: absolute;
        left: 0;
        top: 0;
        padding: 0;

       

        .nav-item {
            margin: 0;
            .nav-link {
                text-align: center;
                padding-left: 0;
                padding-right: 0;
                color: #fff;
                transition: all 0.25s;
                border-radius: 0 !important;
                border: 0;
                &.active {
                    color: $color-primary;
                }
            }

        }
    }
}



.dv-tabs .nav-link {
    border: 0;
    border-bottom: 1px solid #F0F2FA;
    color: $color-black;
    padding: 0 14px 6px 14px;
}

.dv-tabs.nav-tabs .nav-link.active,
.dv-tabs.nav-tabs .nav-item.show .nav-link {
    background-color: transparent !important;
    color: $color-primary;
    border-color: $color-primary;
}


@media (max-width:767px){
    .object-detail .nav-tabs {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: unset;
    }

    #page-nav{
        .nav-item {
            padding: 4px 20px 4px 8px !important;
            &:first-child {
                padding: 4px 8px !important;
            }
            .tab-close {
                background: $color-primary;
                border-radius: 0 10px;
                display: inline-block !important;
            }
            &:hover .tab-close {
                background: $color-white;
                color: $color-primary;
            }
            &.active .tab-close {
                background: $color-white;
            }
        }
    }
}

@media print {
    #page-nav {
        display: none !important;
    }
}

@media (hover: hover) and (pointer: fine) {

    .object-detail-tab .nav-tabs .nav-link:hover {
        color: $color-primary;
        border: 0;
        border-bottom: 2px solid $color-primary;
        cursor: pointer;
    }
}