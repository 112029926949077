
.msp-ctrl-file::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: block;
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    color: $color-dark;
    content: "Browse";
    background-color: $color-gray-2;
    border-left: 1px solid $color-gray-1;
    border-radius: 0 0.2rem 0.2rem 0;
}

.msp-ctrl {
    position: relative;
}

.msp-ctrl .row {
    margin-top: -0.2rem;
    padding: 0;
    height: calc(100% + 0.3rem);
}

.msp-ctrl input[type="text"] {
    border: 0;
    width: 100%;
    height: 100%;
}

.msp-ctrl select {
    border: 0;
    width: 100%;
    height: 100%;
    outline: none;
}

.msp-ctrl-date::before {
    position: absolute;
    font-family: "Font Awesome 5 Free";
    content: "\f073";
    left: 0.25rem;
    top: 0.215rem;
}

.msp-ctrl-fromdate,
.msp-ctrl-todate {
    max-width: 94px
}

.msp-ctrl-time::before {
    position: absolute;
    font-family: "Font Awesome 5 Free";
    content: "\f017";
    left: 0.25rem;
    top: 0.215rem;
}

.msp-ctrl-date input,
.msp-ctrl-time input {
    position: absolute;
    left: 1.5rem;
    top: 1px;
    height: calc(100% - 2px) !important;
    width: calc(100% - 1.75rem) !important;
}

.msp-ctrl[data-required],
.mandate-field {
    border-left: 0.1rem solid $color-danger !important;
}

.msp-ctrl[data-ui="image"][data-required] {
    border-left: 0.1rem solid $color-danger !important;
    border-radius: 0.35rem !important;
}

.msp-ctrl-image-edit[disabled],
.msp-ctrl-image-remove[disabled],
.div-file-uploading a[disabled] {
    pointer-events: none;
}

.div-file-uploading .fa-times {
    position: absolute;
    right: 0rem;
    top: 4px;
    margin-right: 4px;
    color: $color-danger;
    cursor: pointer;
}

.disabled-ctrl {
    opacity: 0.65;
}

.msp-ctrl-multilookup {
    min-height: 1.85rem;
    height: auto !important;
    input {
        display: none;
        margin: 0;
        border: 0;
        outline: 0;
        width: 100%;
    }
    &.focus-ctrl {
        border: 1px solid $color-light-blue;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba($color-light-blue, 0.25);
        input {
            height: 1.25rem;
            display: block;
        }
    }
    .lookup-item {
        max-width: 100%;
        display: inline-flex;
        align-items: baseline;
        &>span {
            @include text-hidden;
        }
    }

}

.msp-ctrl-lookup-multi {
    .btn-outline-secondary {
    padding-right: 1.25rem;
    position: relative;
    margin: 0.125rem;
    display: inline-block;
    }
    p {
        outline: none;
        padding: 2px 4px;
        margin: 1px 0;
        height: 1.5rem;
        width: calc(100% - 0.65rem);
    }
    .fa-times{
        position: absolute;
        right: 0rem;
        top: 2px;
    }
}

.msp-ctrl-lookup {
    overflow: hidden;

    .btn-outline-secondary {
        padding-right: 0;
        position: relative;
        margin: 0;
        width: calc(100%);
        text-align: left;
        padding: 0;
        border: 0 !important;
        &:hover {
            color: $color-light !important;
            background: $color-white !important;
        }
    }
    .fa-times{
        position: absolute;
        right: 0rem;
        top: 2px;
    }

    .lookup-item {
        display: flex;
        &>span {
            @include text-hidden;
            width: calc(100% - 10px);
        }
    }
    
}

.disabled-lookup,
.multi-attachment .disabled-attachment li,
.msp-ctrl-texteditor-disabled .msp-ctrl-texteditor {
    background-color: $color-blue-tint;
}

.form-control:disabled,
.msp-ctrl-texteditor.content-not-editable {
    background-color: $color-blue-tint !important;
    opacity: 1 !important;
    -webkit-text-fill-color: $color-secondary !important;
}

.filter-row{
    .msp-ctrl-lookup{
        .btn-outline-secondary {
            padding: 0;
            border: 0 !important;
            text-align: left;
            margin: 0;
            &:hover {
                background: $color-white;
                color: $color-secondary;
            }

            .fa-times {
                top: 2px;
                right: 0;
            }
        }
    }
}

.lookup-item:hover .attendee-type.contact::before,
.lookup-item:hover .attendee-type.user::before,
.msp-multilookup .item:hover .attendee-type.contact::before,
.msp-multilookup .item:hover .attendee-type.user::before,
.msp-multilookup .item-tag:hover .attendee-type.contact::before,
.msp-multilookup .item-tag:hover .attendee-type.user::before {
    color: $color-white;
}
.msp-ctrl-texteditor.hotkey-enabled .popup-container div.item {
    min-width: 100px;
    border-bottom: dotted 1px $color-black;
}

[data-uid="EventDetail"] [data-group="AddressInformation"] .msp-popup {
    max-width: 100%;
}

[data-group="AddressInformation"] .msp-popup .item {
    @include text-hidden;
}

[data-uid*="ProfilePictureEdit"] .form-group .row,
[data-uid*="EventUploadListPicture"] .form-group .row,
[data-uid*="EventUploadDetailPicture"] .form-group .row {
    display: block;
}

.search-in-list input,
.text-search-area input {
    border-color: $color-secondary;
}

.search-in-list,
.text-search-area {
    // QPC-6615 Text Search class added
    max-width: 260px;
    display: inline-block;
    position: relative;
    width: 100%;
}

.msp-popup {
    padding: 0.25rem 0.5rem;

     .item, .item-tag, .add-new {
        padding: 0.25rem 0.5rem;
        cursor: pointer;
    }

    .item:hover, .item-tag:hover {
        background: $color-secondary;
        color: $color-white;
    }
    .no-data-found {
        padding: 0.25rem 0.5rem;
    }
    .secondary-label {
        display: block;
        font-size: 0.75rem;
        color: $color-dark;
    }

    .item:hover .secondary-label,
    .item-tag:hover .secondary-label{
        color: rgba($color-white, 0.6);
    }
    .item.selected {
        background: $color-primary;
        color: $color-white;
    }
}

.has-error {
    .form-control {
        border-color: $color-danger;
    }
    .vld-message {
        margin-top: 2px;
        font-size: 13px;
        color: $color-danger;
    }
}

.detailPageLabel {
    color: $color-dark !important;
}

.detailPageFields {
    font-size: 14px !important;
    color: $color-black !important;
}

/** lookup field css starts */
.dummy-lookup-format .secondary-label,
[data-field="LookupFormat"] .secondary-label {
    display: block;
    color: $color-secondary;
    font-size: 85%;
}

.dummy-lookup-format {
    height: auto;
    min-height: 2.85rem;
    font-size: 0.8125rem;
    background-color: $color-blue-tint;
}

/** lookup field css ends */

.iti {
    width: 100%;
}

.iti__country-list {
    z-index: 3;
}
.form-control[data-field="MSP_lookup"],
.form-control[data-ui="lookup"] {
    line-height: 1 !important;
}

.msp-auto-complete {
    position: relative;
    width: 150px;
    height: 20px;
    display: inline-block;
  
    .list {
      position: absolute;
      top: 20px;
      left: 0;
      min-height: 60px;
      max-height: 400px;
      overflow-y: auto;
    }
  }
  
  .msp-multi-dropdown {
    position: relative;
    width: 150px;
    min-height: 20px;
    display: inline-block;
  
    .list {
      position: absolute;
      top: 20px;
      left: 0;
      min-height: 60px;
      max-height: 300px;
      overflow-y: auto;
      display: none;
    }
  
    &.expand .list {
      display: block;
    }
  }

  .input-group-append {
    .btn-outline-primary {
        border-color: $color-form-border;
        font-size: 0.85rem !important;
        line-height: 1 !important;
    }
}

.input-group-prepend {
    margin-right: 0;
    .btn-outline-primary {
        border-color: $color-form-border;
        font-size: 0.85rem !important;
        line-height: 1 !important;
    }
    .btn {
        border-right: 0;
    }
}

.msp-time-picker {
    .item {
        color: $color-black !important;
        &.item-selected, &:hover{
            color: $color-white !important;
        }
    }
}


.lblFileUpload {
    width: calc(100% - 50px);
    @include text-hidden;
    margin: 0 !important;
    line-height: 1.4;
}

input.txtSearch[placeholder="Search Box"] {
    display: none;
}

.map input.txtSearch[placeholder="Search Box"] {
    width: 270px;
    top: 10px !important;
    display: block;
}


.uploaded-files {
    .multi-action-button {
        max-width: 65px;
        visibility: hidden;
        i {
            line-height: 1;
        }
    }
    li{
        &:hover{
            .multi-action-button {
                visibility: unset;
            }
        }
        &.list-group-item {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            border: 0;
            border-bottom: 1px solid $color-gray-2;
            z-index: 1;
            margin-bottom: 0;
        }
        &.alert-danger {
            background-color: $alert-danger-bg;
        }
    }
    .multi-file-name {
        flex: 1;
       @include text-hidden;
        padding: .25em 0;
    }
}
@media (max-width:767px){
    .search-in-list,
    .text-search-area {
        max-width: 112px;
    }
}